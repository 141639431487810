import GitHubIcon from '@mui/icons-material/GitHub';
import LanguageIcon from '@mui/icons-material/Language';

import { todoListArray, portfolioArray, coffeedoorCMSArray, cartScootWebArray } from './photoArray';
import { ICard } from './cardTypes';
import nestJS from 'images/projects/nestJS.jpg';
import cleaningCompany from 'images/projects/cleaning-company.jpg';

export const projectData: Array<ICard> = [
    {
        id: 1,
        title: 'CoffeeDoor - coffee and tea shop',
        subtitle: {
            en: 'Next 14 Fullstack version',
            ua: 'Next 14 Fullstack версія',
        },
        imageArray: coffeedoorCMSArray,
        linkArray: [
            {
                id: 1,
                title: 'Open project',
                icon: LanguageIcon,
                link: 'https://coffeedoor-next14-sql.vercel.app',
            },
            {
                id: 2,
                title: 'Open code',
                icon: GitHubIcon,
                link: 'https://github.com/DKotykhin/coffeedoor-next14-sql',
            },
        ],
        description: {
            en: 'Three languages online coffee, tea and accessories store with user personal page and admin panel for CRUD for shop items and coffeeshop menu. Orders history and update users data on personal page. Next 14 server actions. Mobile friendly.',
            ua: "Онлайн магазин кави, чаю та кавових аксесуарів. Підтримує три мови. Адаптивний слайдер для товарів. Персональна сторінка користувача для зміни даних та історії замовленнь. Адмін панель для CRUD операцій з товарами магазину та меню кав'ярні. Адаптивний дизайн.",
        },
        tags: [
            'Next 14',
            "React",
            'Typescript',
            'SQL',
            'Prisma',
            'React Query',
            'Framer Motion',
            "Mapbox",
            'React Hook Form',
            'React Toastify',
            "Tailwind CSS",
            "Zod",
            "Zustand",
            'Swiper',
            "JWT",
            "bcrypt"
        ],
    },
    {
        id: 2,
        title: 'CoffeeDoor - coffee and tea shop',
        subtitle: {
            en: 'Vue 3 - Nuxt 3 Fullstack version',
            ua: 'Vue 3 - Nuxt 3 Fullstack версія',
        },
        imageArray: coffeedoorCMSArray,
        linkArray: [
            {
                id: 1,
                title: 'Open project',
                icon: LanguageIcon,
                link: 'https://coffeedoor-nuxt.vercel.app',
            },
            {
                id: 2,
                title: 'Open code',
                icon: GitHubIcon,
                link: 'https://github.com/DKotykhin/coffeedoor-nuxt',
            },
        ],
        description: {
            en: 'Three languages online coffee, tea and accessories store with user personal page and admin panel for CRUD for shop items and coffeeshop menu. Orders history and update users data on personal page. Nuxt server. Mobile friendly.',
            ua: "Онлайн магазин кави, чаю та кавових аксесуарів. Підтримує три мови. Адаптивний слайдер для товарів. Персональна сторінка користувача для зміни даних та історії замовленнь. Адмін панель для CRUD операцій з товарами магазину та меню кав'ярні. Адаптивний дизайн.",
        },
        tags: [
            'Vue 3',
            "Nuxt 3",
            "Nuxt UI",
            'Typescript',
            'SQL',
            'Prisma',
            "Tailwind CSS",
            "Zod",
            "Pinia",
            "Resend",
            "jsonwebtoken",
            "bcrypt"
        ],
    },
    {
        id: 3,
        title: 'CoffeeDoor Online Shop-CMS version',
        subtitle: {
            en: 'Next 13 and Hygraph CMS version',
            ua: 'Next 13 та Hygraph CMS версія',
        },
        imageArray: coffeedoorCMSArray,
        linkArray: [
            {
                id: 1,
                title: 'Open project',
                icon: LanguageIcon,
                link: 'https://coffeedoor.pp.ua',
            },
            {
                id: 2,
                title: 'Open code',
                icon: GitHubIcon,
                link: 'https://github.com/DKotykhin/Coffeedoor-Next-Hygraph-new',
            },
        ],
        description: {
            en: 'Three languages online coffee, tea and accessories store with latest Next 13 for SSR rendering and GraphQL API. Use Hygraph CMS for content placing. Google analytics. Mobile friendly.',
            ua: 'Онлайн магазин кави, чаю та кавових аксесуарів. Підтримує три мови. Використовує Next JS для SSR рендерингу та GraphQL API. Адаптивний слайдер для товарів. Контент розміщений за допомогою Hygraph CMS. Google аналітика. Адаптивний дизайн.',
        },
        tags: [
            'Next 13',
            'React',
            'Typescript',
            'Zustand',
            'MUI',
            'GraphQL',
            'Hygraph',
            'Swiper',
            'Framer Motion',
            'SASS',
            'Yup',
        ],
    },
    {
        id: 4,
        title: 'Nest JS backend for online shop',
        subtitle: {
            en: 'Full CRUD operations for shop items, orders, menu and users',
            ua: 'Повний цикл CRUD операцій для товарів, замовленнь, меню та користувачів',
        },
        imageArray: [nestJS],
        linkArray: [
            {
                id: 1,
                title: 'Open code',
                icon: GitHubIcon,
                link: 'https://github.com/DKotykhin/coffeedoor-back-nestjs',
            },
        ],
        description: {
            en: 'Nest JS server for online shop. Full CRUD operations for shop items, menu and users. JWT authentication. Bcrypt for password hashing. SQL database. Swagger for API documentation.',
            ua: 'Nest JS сервер для онлайн магазину. Повний цикл CRUD операцій для товарів, меню та користувачів. JWT аутентифікація. Bcrypt для хешування паролів. SQL база даних. Swagger для документації API.',
        },
        tags: [
            'Nest JS',
            'Typescript',
            'SQL',
            'TypeORM',
            'Swagger',
            'JWT',
            'bcrypt',
            'Passport',
            'sendgrid/mail',
            'AWS S3',
        ],
    },
    {
        id: 5,
        title: 'Nest JS API Gateway for microservices',
        subtitle: {
            en: 'Rest API and GraphQL API Gateway',
            ua: 'Rest API and GraphQL API Gateway',
        },
        imageArray: [nestJS],
        linkArray: [
            {
                id: 1,
                title: 'Rest API',
                icon: GitHubIcon,
                link: 'https://github.com/DKotykhin/coffeedoor-api-gateway',
            },
            {
                id: 2,
                title: 'GraphQL',
                icon: GitHubIcon,
                link: 'https://github.com/DKotykhin/coffeedoor-api-gateway-graph-ql',
            },
        ],
        description: {
            en: 'Nest JS API Gateway server for online shop microservices. Full CRUD operations for shop items, orders, menu and users. JWT role based authentication. Swagger for API documentation.',
            ua: 'Nest JS сервер для мікросервісів онлайн магазину. Повний цикл CRUD операцій для товарів, замовленньб меню та користувачів. JWT аутентифікація. Swagger для документації API.',
        },
        tags: [
            'Nest JS',
            'Typescript',
            'gRPC',
            'Swagger',
            'GraphQL',
            'JWT',
            'bcrypt',
            'passport',
            'class-validator',
            'AWS S3',
        ],
    },
    {
        id: 6,
        title: 'Nest JS microservices for online shop',
        subtitle: {
            en: 'Store, Menu, Orders, Users microservices',
            ua: 'Store, Menu, Orders, Users мікросервіси',
        },
        imageArray: [nestJS],
        linkArray: [
            {
                id: 1,
                title: 'Menu',
                icon: GitHubIcon,
                link: 'https://github.com/DKotykhin/coffeedoor-menu-microservice',
            },
            {
                id: 2,
                title: 'Store',
                icon: GitHubIcon,
                link: 'https://github.com/DKotykhin/coffeedoor-store-microservice',
            },
            {
                id: 3,
                title: 'Orders',
                icon: GitHubIcon,
                link: 'https://github.com/DKotykhin/coffeedoor-order-microservice',
            },
            {
                id: 4,
                title: 'Users',
                icon: GitHubIcon,
                link: 'https://github.com/DKotykhin/coffeedoor-user-microservice',
            },
        ],
        description: {
            en: 'Nest JS microservices for online shop. Full CRUD operations for shop items, orders, menu and users. JWT authentication. SQL database.',
            ua: 'Nest JS мікросервіси для онлайн магазину. Повний цикл CRUD операцій для товарів, замовленнь, меню та користувачів. JWT аутентифікація. SQL база даних.',
        },
        tags: [
            'Nest JS',
            'Typescript',
            'SQL',
            'TypeORM',
            'gRPC',
            'sendgrid/mail',
            'class-validator',
        ],
    },
    {
        id: 7,
        title: 'TodoList - Easy FullStack Todo App',
        subtitle: {
            en: 'Rest API and GraphQL API with Node JS server',
            ua: 'Rest API and GraphQL API with Node JS server',
        },
        imageArray: todoListArray,
        linkArray: [
            {
                id: 1,
                title: 'Url',
                icon: LanguageIcon,
                link: 'https://mytodolist.fun',
            },
            {
                id: 2,
                title: 'Rest API',
                icon: GitHubIcon,
                link: 'https://github.com/DKotykhin/TodoList-Frontend-RTK-Query',
            },
            {
                id: 3,
                title: 'GraphQL',
                icon: GitHubIcon,
                link: 'https://todolist-frontend-graphql.vercel.app',
            },
            {
                id: 4,
                title: 'Rest back',
                icon: GitHubIcon,
                link: 'https://github.com/DKotykhin/TodoList-Backend-NestJS',
            },
            {
                id: 5,
                title: 'GraphQL back',
                icon: GitHubIcon,
                link: 'https://github.com/DKotykhin/TodoList-Backend-Apollo',
            },
        ],
        description: {
            en: 'Easy TodoList with full CRUD operations for users and their tasks. REST API, Mongo DB for a database. User login/logout, delete and registration. Validation all fields, restore password via email. Sort, search for task. Full description in gitHub.',
            ua: 'Простий застосунок для керування справами. Підтримує повний цикл CRUD операцій для користувачів та їх справ. База даних на Mongo DB, REST API. Вхід, вихід, реєстрація та видалення користувачів. Валідація всіх форм, відновлення паролю через пошту. Сортування, пошук для справ. Повний опис на gitHub.',
        },
        tags: [
            'React',
            'Redux',
            'Apollo',
            'Node JS',
            'GraphQL',
            'RTK Query',
            'Typescript',
            'React Hook Form',
            'MUI',
            'SASS',
            'Yup',
            'EasyMDE',
        ],
    },
    {
        id: 8,
        title: 'Cleaning Company',
        subtitle: {
            en: 'Landing page for cleaning company',
            ua: 'Лендінг для компанії з прибирання',
        },
        imageArray: [cleaningCompany],
        linkArray: [
            {
                id: 1,
                title: 'Open project',
                icon: LanguageIcon,
                link: 'https://splendid-platypus-670577.netlify.app',
            },
            {
                id: 2,
                title: 'Open code',
                icon: GitHubIcon,
                link: 'https://github.com/DKotykhin/cleaning-company',
            },
        ],
        description: {
            en: 'Landing page for cleaning company. Mobile friendly.',
            ua: 'Лендінг для компанії з прибирання. Адаптивний дизайн.',
        },
        tags: [
            'React',
            'Next 13',
            'Typescript',
            'React Scroll',
            'React Hook Form',
            'Yup',
            'SendGrid',
            'SASS',
            'Swiper',
            'React-toastify',
        ],
    },
    {
        id: 9,
        title: 'Cart Scoot Web - service for renting carts',
        subtitle: {
            en: 'App for renting carts both for drivers and riders',
            ua: 'Додаток для оренди карет для водіїв та пасажирів',
        },
        imageArray: cartScootWebArray,
        linkArray: [
            {
                id: 1,
                title: 'Open project',
                icon: LanguageIcon,
                link: 'https://cart-scoot-web-frontend-three.vercel.app',
            },
            {
                id: 2,
                title: 'Open front code',
                icon: GitHubIcon,
                link: 'https://github.com/DKotykhin/cart_scoot_web-frontend',
            },
            {
                id: 3,
                title: 'Open back code',
                icon: GitHubIcon,
                link: 'https://github.com/DKotykhin/cart_scoot_web-backend',
            },
        ],
        description: {
            en: 'App for renting carts both for drivers and riders. Mobile friendly. Full CRUD operations for users. REST API, Mongo DB for a database. User login/logout, delete and registration. Validation all fields, restore password via email. Tracking carts on the map.',
            ua: 'Додаток для оренди карет для водіїв та пасажирів. Адаптивний дизайн. Повний цикл CRUD операцій для користувачів. База даних на Mongo DB, REST API. Вхід, вихід, реєстрація та видалення користувачів. Валідація всіх форм, відновлення паролю через пошту. Відстеження карет на мапі.',
        },
        tags: [
            'React',
            'Next 14',
            'Typescript',
            'Apollo',
            'React Hook Form',
            'Yup',
            'SendGrid',
            'SASS',
            'Swiper',
            'React-toastify',
            'GraphQL',
            'zustand',
            'AWS S3',
            'Mapbox',
            'MongoDB',
        ],
    },
    {
        id: 10,
        title: 'Portfolio',
        subtitle: {
            en: 'My Portfolio Project',
            ua: 'Проект мого портфоліо',
        },
        imageArray: portfolioArray,
        linkArray: [
            {
                id: 1,
                title: 'Open project',
                icon: LanguageIcon,
                link: 'https://dmytro-kotykhin.netlify.app',
            },
            {
                id: 2,
                title: 'Open code',
                icon: GitHubIcon,
                link: 'https://github.com/DKotykhin/Portfolio',
            },
        ],
        description: {
            en: "This 2-languages SPA helps introduce my skills in professional way. It's a simply way to see my own projects",
            ua: 'Застосунок допомагає представити мій професійний досвід. Підтримує дві мови. Найпростіший спосіб подивитися мої проекти',
        },
        tags: [
            'React',
            'Typescript',
            'React Scroll',
            'React Hook Form',
            'Framer Motion',
            'MUI',
            'EmailJS',
            'SASS',
            'i18next',
            'Swiper',
            'Yup',
            'React GA4',
        ],
    },
];
